/**
 * Navigation
 *
 * @author Simple Digital
 */

/**
 * Handles toggling mobile nav menu & accessibility features
 * (e.g. tabbing dropdowns)
 */
SDG.navigationScripts = {

    fixCurrentActiveStates: function() {
        /* Check that current page item is not set in primary navigation */
        if ($('#primary-navigation .current-menu-item').length === 0) {
            if (document.location.pathname.indexOf('blog') > 0) {
                var blogItems = $('.menu-item a[href*="/blog"]');
                if (blogItems.length) {
                    $.each(blogItems, function() {
                        $(this).parent('li').addClass('current-menu-item');
                    });
                }
            }
        }
    },


    panelify: function(selector, collapsed) {
        var menus = $(selector);
        menus.addClass('expandable').addClass((collapsed === 'undefined' || collapsed) ? 'collapsed ready' : 'ready');
        $(document).on('click', selector + ' .toggle-menu', function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            var menu = $(this).closest('.expandable'),
                collapsedState = !menu.hasClass('collapsed');
            menu.toggleClass('collapsed', collapsedState);
            localStorage.setItemStorage('sidebarState', collapsedState);
            window.setTimeout(function() {
                $(window).trigger('resize');
            }, 250);
        });
        menus.toggleClass('collapsed', toBool(localStorage.getItemStorage('sidebarState')));
    },

    naviScroll: function() {
        $('#primary-nav ul li a').each(function() {
            var me = $(this);
            $('[href="' + $(this).attr('href') + '"]').not(me).on('click', function() {
                console.log('click!');
                me.click();
            });
        });
    },

    navigationToggles: function() {
        var toggleNav = '.main-mobile-trigger',
            primaryNav = '.main-navigation',
            menu = $(toggleNav).parents('menu');

        $(window).on('keyup', function(e) {
            if (e.keyCode == '27') {
                if ($('.main-navigation').hasClass('active')) {
                    $(toggleNav).first().click();
                }
            }
        });

        $(document).on('click', toggleNav, function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $(this).toggleClass('active');
            $(primaryNav).toggleClass('active');
            menu.toggleClass('open');
            $('header.site-header').toggleClass('menu-open');
        });

        (function() {
            var container, button, menu, links, i, len;

            container = document.getElementById('site-navigation');
            if (!container) {
                return;
            }

            button = container.getElementsByTagName('button')[0];
            if ('undefined' === typeof button) {
                return;
            }

            menu = container.getElementsByTagName('ul')[0];

            // Hide menu toggle button if menu is empty and return early.
            if ('undefined' === typeof menu) {
                button.style.display = 'none';
                return;
            }

            menu.setAttribute('aria-expanded', 'false');
            if (-1 === menu.className.indexOf('nav-menu')) {
                menu.className += ' nav-menu';
            }

            button.onclick = function() {
                if (-1 !== container.className.indexOf('toggled')) {
                    container.className = container.className.replace(' toggled', '');
                    button.setAttribute('aria-expanded', 'false');
                    menu.setAttribute('aria-expanded', 'false');
                } else {
                    container.className += ' toggled';
                    button.setAttribute('aria-expanded', 'true');
                    menu.setAttribute('aria-expanded', 'true');
                }
            };

            // Get all the link elements within the menu.
            links = menu.getElementsByTagName('a');

            // Each time a menu link is focused or blurred, toggle focus.
            for (i = 0, len = links.length; i < len; i++) {
                links[i].addEventListener('focus', toggleFocus, true);
                links[i].addEventListener('blur', toggleFocus, true);
            }

            /**
             * Sets or removes .focus class on an element.
             */
            function toggleFocus() {
                var self = this;

                // Move up through the ancestors of the current link until we hit .nav-menu.
                while (-1 === self.className.indexOf('nav-menu')) {

                    // On li elements toggle the class .focus.
                    if ('li' === self.tagName.toLowerCase()) {
                        if (-1 !== self.className.indexOf('focus')) {
                            self.className = self.className.replace(' focus', '');
                        } else {
                            self.className += ' focus';
                        }
                    }

                    self = self.parentElement;
                }
            }

            /**
             * Toggles `focus` class to allow submenu access on tablets.
             */
            (function(container) {
                var touchStartFn, i,
                    parentLink = container.querySelectorAll('.menu-item-has-children > a, .page_item_has_children > a');

                if ('ontouchstart' in window) {
                    touchStartFn = function(e) {
                        var menuItem = this.parentNode,
                            i;

                        if (!menuItem.classList.contains('focus')) {
                            e.preventDefault();
                            for (i = 0; i < menuItem.parentNode.children.length; ++i) {
                                if (menuItem === menuItem.parentNode.children[i]) {
                                    continue;
                                }
                                menuItem.parentNode.children[i].classList.remove('focus');
                            }
                            menuItem.classList.add('focus');
                        } else {
                            menuItem.classList.remove('focus');
                        }
                    };

                    for (i = 0; i < parentLink.length; ++i) {
                        parentLink[i].addEventListener('touchstart', touchStartFn, false);
                    }
                }
            }(container));
        })();

        /**
         * File skip-link-focus-fix.js.
         *
         * Helps with accessibility for keyboard only users.
         *
         * Learn more: https://git.io/vWdr2
         */
        (function() {
            var isIe = /(trident|msie)/i.test(navigator.userAgent);

            if (isIe && document.getElementById && window.addEventListener) {
                window.addEventListener('hashchange', function() {
                    var id = location.hash.substring(1),
                        element;

                    if (!(/^[A-z0-9_-]+$/.test(id))) {
                        return;
                    }

                    element = document.getElementById(id);

                    if (element) {
                        if (!(/^(?:a|select|input|button|textarea)$/i.test(element.tagName))) {
                            element.tabIndex = -1;
                        }

                        element.focus();
                    }
                }, false);
            }
        })();

    },

    closeNav: function() {
        $('.primary-nav').closest('header').removeClass('overlay-active');
    },

    init: function() {
        var $ = jQuery;
        this.fixCurrentActiveStates();
        this.navigationToggles();
        this.naviScroll();
        // this.panelify('#sidebar');
        // this.stickyNavigation();
    }

};
