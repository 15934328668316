/**
 * Flyzoo Chat
 *
 * @author Carely
 */

SDG.pluginServices = {

    FlyzooChat: {

        preLoad: function() {
            SDG.PLUGINS.FLYZOO = {
                started:            false,
                completed:          false,
                timeoutReached:     null,
                healthyLoad:        null
            };
        },

        initialize: function() {
            var me = this;
            (function () {
                SDG.PLUGINS.FLYZOO.started = true;
                var $ = jQuery;
                SDG.log('load FlyZoo 🙄 [1] (this will only appear on development environments)');
                document.body.$_appendChild_ = document.body.appendChild;
                document.body.appendChild = function() {
                    if ( $('#chat-container').find('[id*="flyzoo"],[class*="flyzoo"]').length >= 23 ) {
                        SDG.PLUGINS.FLYZOO.timeoutReached = false;
                        me.postLoad();
                    }
                    if ( arguments.length != 1 || SDG.PLUGINS.FLYZOO.completed ) {
                        document.body.$_appendChild_(arguments);
                    } else {
                        var arg = arguments[0];
                        var isItFlyZoo = (
                            (arg.querySelector('[id^="flyzoo"],[class^="flyzoo"]') !== null) ||
                            ($(arg).attr('class') && $(arg).attr('class').indexOf('flyzoo') > -1 )
                            );
                        if ( isItFlyZoo ) {

                            document.getElementById('chat-container').appendChild(arg);
                        } else {
                            document.body.$_appendChild_(arguments);
                        }
                    }
                };
                // };
                window._FlyzooApplicationId="54e0b42c684ea80e6cbe13d954e0b407684ea80e6cbe139f";
                var fz = document.createElement('script');
                fz.type = 'text/javascript'; fz.async = true;
                fz.src = '//widget.flyzoo.co/scripts/flyzoo.start.js';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(fz, s);
            })();
        },

        postLoad: function() {
            document.body.appendChild = document.body.$_appendChild_;
            SDG.PLUGINS.FLYZOO.completed = true;
            SDG.PLUGINS.FLYZOO.healthyLoad = !(SDG.PLUGINS.FLYZOO.timeoutReached);

        },

        onComplete: function() {
            var me = this;
            if ( !SDG.PLUGINS.FLYZOO.completed ) {
                window.setTimeout(function() {
                    SDG.PLUGINS.FLYZOO.timeoutReached = true;
                    me.postLoad();
                }, 500);
            }
        }

    }

};
