/**
 * jQuery Extensions
 *
 * @author Simple Digital
 */

/**
 * Form jQuery Extensions
 */
$.prototype.clearVals = function() {
    if ($(this).find(":input").length) {
        $(this).find(":input").val('').prop('checked', false).trigger('change');
    }
};

$.prototype.clearVal = function() {
    if ($(this).is(":input")) {
        $(this).val('').prop('checked', false).trigger('change');
    }
};

$.prototype.disable = function(disable, classAttr) {
    disable = (typeof disable === 'undefined') ? true : disable;
    classAttr = (typeof classAttr === 'undefined') ? 'disabled' : classAttr;
    $(this).prop('disabled', disable).addClass(classAttr);
    if (typeof $(this).getSelf() === 'object') {
        $(this).getSelf().setAttribute('disabled', '');
    }
};

$.prototype.enable = function(classAttr) {
    classAttr = (typeof classAttr === 'undefined') ? 'disabled' : classAttr;
    $(this).prop('disabled', false).removeClass(classAttr);
};

$.prototype.getSelf = function(fallback) {
    fallback = (typeof fallback === 'undefined') ? false : fallback;
    var me = this.get();
    if (me.length === 1) {
        return me[0];
    } else {
        return fallback;
    }
};

(function($) {
    var radioCheck = /radio|checkbox/i,
        keyBreaker = /[^\[\]]+/g,
        numberMatcher = /^[\-+]?[0-9]*\.?[0-9]+([eE][\-+]?[0-9]+)?$/;

    var isNumber = function(value) {
        if (typeof value == 'number') {
            return true;
        }

        if (typeof value != 'string') {
            return false;
        }

        return value.match(numberMatcher);
    };

    scrollCounter = 0;

    $.fn.extend({
        /**
         * @parent dom
         * @download http://jmvcsite.heroku.com/pluginify?plugins[]=jquery/dom/form_params/form_params.js
         * @plugin jquery/dom/form_params
         * @test jquery/dom/form_params/qunit.html
         * <p>Returns an object of name-value pairs that represents values in a form.
         * It is able to nest values whose element's name has square brackets. </p>
         * Example html:
         * @codestart html
         * &lt;form>
         *   &lt;input name="foo[bar]" value='2'/>
         *   &lt;input name="foo[ced]" value='4'/>
         * &lt;form/>
         * @codeend
         * Example code:
         * @codestart
         * $('form').formParams() //-> { foo:{bar:2, ced: 4} }
         * @codeend
         *
         * @demo jquery/dom/form_params/form_params.html
         *
         * @param {Boolean} [convert] True if strings that look like numbers and booleans should be converted.  Defaults to true.
         * @return {Object} An object of name-value pairs.
         */
        formParams: function(convert) {
            if (this[0].nodeName.toLowerCase() == 'form' && this[0].elements) {

                return jQuery(jQuery.makeArray(this[0].elements)).getParams(convert);
            }
            return jQuery("input[name], textarea[name], select[name]", this[0]).getParams(
                convert);
        },

        getParams: function(convert) {
            var data = {},
                current;

            convert = convert === undefined ? true : convert;

            this.each(function() {
                var el = this,
                    type = el.type && el.type.toLowerCase();
                //if we are submit, ignore
                if ((type == 'submit') || !el.name) {
                    return;
                }

                var key = el.name,
                    value = $.data(el, "value") || $.fn.val.call([el]),
                    isRadioCheck = radioCheck.test(el.type),
                    parts = key.match(keyBreaker),
                    write = !isRadioCheck || !!el.checked,
                    //make an array of values
                    lastPart;

                if (convert) {
                    if (isNumber(value)) {
                        value = parseFloat(value);
                    } else if (value === 'true' || value === 'false') {
                        value = Boolean(value);
                    }

                }

                // go through and create nested objects
                current = data;
                for (var i = 0; i < parts.length - 1; i++) {
                    if (!current[parts[i]]) {
                        current[parts[i]] = {};
                    }
                    current = current[parts[i]];
                }
                lastPart = parts[parts.length - 1];

                //now we are on the last part, set the value
                if (lastPart in current && type === "checkbox") {
                    if (!$.isArray(current[lastPart])) {
                        current[lastPart] = current[lastPart] === undefined ?
                            [] : [current[lastPart]];
                    }
                    if (write) {
                        current[lastPart].push(value);
                    }
                } else if (write || !current[lastPart]) {
                    current[lastPart] = write ? value : undefined;
                }

            });
            return data;
        },
        /* ---------------------------------------------- */

        /* ---------------------------------------------- */
        /*  Viewport Distance Helpers
        /* ---------------------------------------------- */
        viewportOffset: function(from) {
            var me = $(this);
            if (me.length) {
                from = (typeof from === 'undefined') ? 'top' : from;
                if (from === 'top') {
                    return me.offset().top - $(window).scrollTop();
                } else if (from === 'left') {
                    return me.offset().left - $(window).scrollLeft();
                }
                // else if (from === 'right') {
                //     return Number.parseInt(window.innerWidth) - Number.parseInt(me.offset().left + me.width());
                // } else if (from === 'bottom') {
                //     return Number.parseInt(window.innerHeight) - Number.parseInt(me.offset().top + me.height());
                // }
            }
        },
        /* ---------------------------------------------- */

        /* ---------------------------------------------- */
        /*  Unique Attribute Values With jQuery
        /* ---------------------------------------------- */
        uniqueAttr: function(attribute) {
            var seen = {};
            $(this).map(function() {
                var value = $(this).attr(attribute);
                // or $(this).attr('data-xyz');
                // or $(this).data('xyz');

                if (seen.hasOwnProperty(value)) {
                    return null;
                }

                seen[value] = true;
                return value;
            }).get();
            return seen;
        },

        scrolled: function(waitTime, fn) {
            if (typeof waitTime === "function") {
                fn = waitTime;
                waitTime = 500;
            }
            var tag = "scrollTimer" + scrollCounter++;
            $(window).scroll(function() {
                var self = $(this);
                var timer = self.data(tag);
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                    self.removeData(tag);
                    fn.call(self[0]);
                }, waitTime);
                self.data(tag, timer);
            });
        }
        /* ---------------------------------------------- */
    });

})(jQuery);
