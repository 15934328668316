/**
 * Keypress JavaScript Events
 *
 * @author Carely
 */

SDG.keypressEvents = function() {
    var $ = jQuery;
    $(document).keyup(function(event) {

        var activeModiferKeys = {
            cmd: (navigator.platform.startsWith('Win')) ? event.ctrlKey : event.metaKey,
            alt: (navigator.platform.startsWith('Win')) ? event.altKey : event.ctrlKey,
            shift: shiftModifierActive = event.shiftKey
        };

        /*  Escape key */
        if (event.keyCode == 27) {
            SDG.modalEvents.closeModals();
            SDG.navigationScripts.closeNav();
        }
        /* Enter/Return Key */ // else if (event.keyCode == 13) { }
        /* Up/Down Keys */ // else if (event.which == 40 || event.which == 9 || event.which == 38) { }

        /* Check keybinding for active modifiers (shift,ctrl,alt,super,etc..) */
        // if (activeModiferKeys.cmd) {
            
        // }

    });
};
