/**
 * Modals
 */

SDG.modalEvents = function() {
    var $ = jQuery;
    var modals = $('.modal'),
        hideTriggers = $('.modal,.modal-close'),
        showTriggers = $('.modal-trigger');

    showTriggers.on('click', function(event) {
        if ($(this).is('[disabled]')) {
            return false;
        } else {
            event.preventDefault();
            event.stopImmediatePropagation();
            var forTarget = $('#' + $(this).attr('data-modal-target'));
            console.log(forTarget);
            if (forTarget.length >= 1) {
                $('.modal.active').removeClass('active');
                forTarget.addClass('active');
            }
        }
    });

    hideTriggers.on('click', function(event) {
        if( jQuery(event.target).parents('.modal-inner').length && !jQuery(event.target).is('a.modal-close') ){
            return true;
        } else {
            event.preventDefault();
            SDG.modalEvents.closeModals();
        }
    });
};

SDG.modalEvents.closeModals = function() {
    var $ = jQuery;
    $(document).trigger('closeModals');
    if ($('.modal.active').length) {
        $('.modal:not(.always-open)').removeClass('active');
    }
    if ( $('.module-list-wrapper .expanded').length ) {
        $('.close-modal:visible').trigger('click');
    }
};