/**
 * Main JavaScript Events
 *
 * @author Carely
 */

/** FIRST CALLS */

/** On Document Load */
jQuery(document).ready(function () {
    /** PRIMARY SCRIPTS */
    SDG.initGlobals();
    SDG.browserBookmarker();
    SDG.navigationScripts.init();
    SDG.keypressEvents();
    SDG.generalJavaScripture();
    SDG.searchOverlay();
    SDG.searchForm();
    SDG.animations();
    SDG.sliderizer();
    SDG.anchorContent();
    SDG.backToTop();
    SDG.cardTricks();

    /** LAST CALLS */

    /* SDG.webAppHacks(); SDG.formScripture(); SDG.collapsibleAccordions(); SDG.modalEvents(); */
});

/** On Window Resize */
jQuery(window).on('resize', function () {
    SDG.equalHeightsSmallest();
    SDG.equalHeights();
    SDG.equalHeightsInner('equal-inner-alt');
    SDG.equalHeightsMobile();
    SDG.sliderizerPostLoad();
});

/** On Window Scroll */
// jQuery(window).on('scroll', function () {});

/** On Window Load */
jQuery(window).on('load', function () {
    /** Trigger Artificial Events */
    jQuery(window).trigger('scroll');
    jQuery(window).trigger('resize');

    setTimeout(function () {
        SDG.masonryLayouts();
    }, 500);

    /** Admin Front-End Stuff */
    SDG.adminFrontendTweaks();

    /** LAST WINDOW-LOAD METHODS */
});
