/**
 * Global JavaScripture
 *
 * @author Simple Digital
 */

$.GLOBALS = $.GLOBALS || {};

var SDG = SDG || {};

SDG.initGlobals = function() {

    var $ = jQuery;

    $.GLOBALS = {

        themeDir: (
            (function() {
                return (
                    localStorage.getItemStorage('themeDir') ||
                    (function() {
                        var myTheme = jQuery('script[src*="wp-content/themes/"]');
                        myTheme = (myTheme[0] !== undefined) ? myTheme[0].src.replace(/themes\/(.*?)\/(.*)/g, 'themes/$1') : null;
                        localStorage.setItemStorage('themeDir', myTheme);
                        return myTheme;
                    })()
                );
            })()
        ),

        domainAndProtocol: (
            location.origin = location.protocol + "//" +
            location.hostname + (location.port ? ':' + location.port : '')
        ),

        siteName: (
            document.title.split('|')[document.title.split('|').length - 1]
        ),

        ENV: (
            function() {
                return (
                    (
                        document.domain.reverse().indexOf('.local'.reverse())   === 0 ||
                        document.domain.replace('www.', '').indexOf('local.')   === 0 ||
                        document.domain.replace('www.', '').indexOf('dev.')     === 0 ||
                        document.domain.replace('www.', '').indexOf('staging.') === 0 /**/
                    ) ? 'DEVELOPMENT' : 'PRODUCTION'
                );
            }
        )(),

        smallBreak: 520,

        mediumBreak: 768,

        largeBreak: 1376,

        colorKeys: [
            'primary',
            'secondary',
            'tertiary',
            'quaternary',
            'quinary',
            'senary'
        ],

        colorPalette: [
            '#fd8580',
            '#b495dc',
            '#ebe9e6',
            '#383f40',
            '#578eba',
            '#488e91'
        ]

    };

    $.GLOBALS.debug = ($.GLOBALS.ENV === 'DEVELOPMENT');

    SDG.setCookieStorage('SDG.DEBUG', $.GLOBALS.debug ? 'true' : 'false');
};

