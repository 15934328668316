/**
 * Infinite Scroll
 *
 * @author Carely
 */

SDG.infiniteScroll = function (append) {
    // var $ = jQuery;
    // append = true;


    // var loadTrigger = '.load-more-trigger.next',
    //     targetContent = '.load-more-content';


    // if ($('.load-more-wrapper').length) {

    //     $('html').addClass('no-animations');
    //     window.scrollTo(0, {
    //         duration: 0
    //     });

    //     window.onbeforeunload = function () {
    //         $('html').addClass('no-animations');
    //         window.scrollTo(0, {
    //             duration: 0
    //         });
    //     };

    //     SDG.historyEvents();

    //     $('html').removeClass('no-animations');

    //     $(document).on('click', loadTrigger, function (event) {
    //         event.preventDefault();
    //         if ( !$(this).is('[disabled]') && $(this).hasClass('hot') ) {
    //             var me = $(this),
    //                 currentTitle = document.title.split('|')[0].trim();
    //             me.removeClass('hot');
    //             SDG.loaders.toggleAfter(true, me);

    //             var loadHref = me.attr('href'),
    //                 wrapper = me.closest('.load-more-wrapper'),
    //                 $ItemContent;

    //             AJAX_TIME = new Date().getTime();

    //             $.ajax({
    //                 url: loadHref,
    //                 context: document.body,
    //                 // beforeSend: function() {},
    //                 success: function (result) {
    //                     var totalTime = ((new Date().getTime()) - AJAX_TIME),
    //                         $ItemContent = $(result).find('main'),
    //                         nextTrigger = $ItemContent.find(loadTrigger),
    //                         nextID = $($ItemContent).find(targetContent).attr('id'),
    //                         newTitle = result.match(/<(title)>[\s\S]*?<\/\1>/gi)[0].replace('<title>', '').replace(
    //                             '</title>', ''),
    //                         pageTheme = $($ItemContent).data('body-class');

    //                     $('body').removeClass('default-copy-color light-copy-color dark-copy-color');

    //                     var prevLength = $('.load-more-wrapper').find(targetContent).find('>*').length,
    //                         scrollToSelector = '.load-more-wrapper .load-more-content >*:nth-child(' + (prevLength + 1) + ')';

    //                     me.fadeOut(200, function() {
    //                         $(this).text(currentTitle);
    //                         $(this).fadeIn();
    //                         $(this).addClass('anchor-link')
    //                             .attr(
    //                                 'href',
    //                                 document.location.href
    //                             )
    //                             .attr(
    //                                 'data-push-anchor',
    //                                 '#' + $(this).closest('aside').prev('.load-more-content').attr('id')
    //                             )
    //                         ;
    //                     });

    //                     /* me.parents('.pagination-nav').animate({ height: 0, padding: 0, minHeight: 0, opacity: 0, display: 'none' }, 'easeOutExpo', function () { } ); */

    //                     $('.load-more-wrapper')
    //                         .append(
    //                             $($ItemContent).find(targetContent).addClass('animated fadeInUp collapsed')
    //                         )
    //                         .append(
    //                             $($ItemContent).find('.pagination-details').addClass('animated inactive collapsed').attr('data-animation', 'fadeInUp')
    //                         );

    //                     setTimeout(function () {
    //                         var newContent = $('.load-more-content:last'),
    //                             oldPreview = newContent.prev('.pagination-details').find('.hero'),
    //                             oldTrigger = oldPreview.prev('nav').find('.pagination-trigger');

    //                         oldTrigger.parents('.pagination-nav').addClass('used');
    //                             // newContent.removeClass('hidden');
    //                             SDG.sliderizer();
    //                             SDG.animations();
    //                             newContent.removeClass('collapsed');
    //                             SDG.loaders.toggleAfter(false, oldTrigger);

    //                             var items = oldPreview.find('[data-swap-class]').andSelf(),
    //                                 count = items.length;

    //                             items.each(function(i) {
    //                                 $(this).attr('class', $(this).data('swap-class'));
    //                                 if (i+1 === count) {
    //                                     SDG.pushHistoryState(loadHref);
    //                                     document.title = newTitle;
    //                                     if (oldPreview.visible(true)) {
    //                                         SDG.scrollToElement(oldPreview);
    //                                     }
    //                                     newPreview = newContent.next('.pagination-details').removeClass('collapsed');
    //                                     $(window).trigger('resize').trigger('scroll');
    //                                 }
    //                             });
    //                         }, 500
    //                     );

    //                     if (nextTrigger.attr('href') === undefined) {
    //                         $('.pagination-details').remove();
    //                         $('.loading-container').hide().remove();
    //                         $('.load-more-wrapper').append('<div class="row full-row pad-top pad-bottom animated fadeInUp" style="' + $('.load-more-content:last').attr('style') + '"><div class="col magnify primary-color text-center"><p class="text-center block-center magnify h5"><a class="text-center button primary" href="/our-work/">View All Case Studies</a></p></div></div>');
    //                     }
    //                 },

    //                 complete: function () {
    //                     // console.log('Done!');
    //                 },

    //                 error: function (response) {
    //                     alert(
    //                         'Looks like there was an issue with your connection. Maybe try again?'
    //                     );
    //                 }
    //             }).done(function () {});
    //         }
    //     });
    // }
};