/**
 * Grid/Masonry Layouts
 */

SDG.masonryLayouts = function() {

    if ( $('.masonry').length ) {
        $('.masonry').each(function() {
            var me = $(this);
            if (me.find('.grid-sizer').length === 0) {
                me.prepend('<div class="grid-sizer"></div>');
            }
            var $grid = me.masonry({
                itemSelector: '.item',
                percentPosition: true,
                columnWidth: '.grid-sizer'
            });
            if (!me.hasClass('initialized')) {
                /* layout Masonry after each image loads */
                $grid.imagesLoaded().progress(function() {
                    $grid.masonry();
                });
                me.addClass('initialized');
            }
        });
    }

};
