/**
 * Anchor Content
 *
 * @author Simple Digital
 */

SDG.anchorContent = function() {
    var $ = jQuery;

    $(document).on('click', 'a[href*="/#/"],.anchor-link:not(.actual-trigger):not(.filter-item-target)', function(event) {
        // event.preventDefault();
        var pushHref = $(this).attr('href'),
            anchorID = $(this).data('push-anchor') || pushHref.replace('/#/', '').replace('#', '').replaceAll('/', '');

        if (anchorID !== '#' && $('#' + anchorID).length === 1) {
            SDG.scrollToElement('#' + anchorID);
        } else {
            document.location = pushHref;
        }
        if ($(this).data('push-anchor')) {
            SDG.pushHistoryState(pushHref);
        } else {
            SDG.pushHashState(pushHref);
        }
        if ( $(this).parents('.anchor-nav').length ) {
            $(this).parent('li').addClass('current').siblings().removeClass('current');
        }
        if ( $('.back-to-top').length ) {
            $('.back-to-top').addClass('active');
        }
    });

    if ( SDG.getHashSlug().length > 0 && $('#' + SDG.getHashSlug()).length ) {
        SDG.scrollToElement('#' + SDG.getHashSlug());
    }

};
