/**
 * Admin Frontend
 *
 * @author Simple Digital
 */


var SDG = SDG || {};

SDG.adminFrontendTweaks = function() {
    var $ = jQuery;
    if ( $('#wpadminbar').length || $('body.admin-bar').length ) {
        $('html').addClass('admin-bar-present');
    }
};