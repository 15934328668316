/**
 * Initialize JS
 *
 * @author Simple Digital
 */

if (typeof jQuery !== 'undefined') {
    $ = jQuery;
}

var SDG = SDG || {};

SDG.PLUGINS = {};
