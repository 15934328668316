/**
 * Browser Compatibility
 *
 * @author Simple Digital
 */

/**
 * Add IE Version Classes
 *
 * @return void
 */
SDG.addIEVersionClasses = function() {
    var $ = jQuery;
    var ua = window.navigator.userAgent,
        msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        $('html').addClass('ie ie-' + (parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)))));
    }
    return false;
};
