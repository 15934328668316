/**
 * Infinite Scroll
 *
 * @author Simple Digital Marketing
 */

// SDG.infiniteScroll = function(append) {
//     if ( typeof append === 'undefined') {
//         append = true;
//     }
//     var loadWrapper = $('.load-more-wrapper'),
//         loadTrigger = $('.load-more-trigger'),
//         loadContent = $('.load-more-content');

//     loadTrigger.on('click', function(event) {
//         event.preventDefault();
//         event.stopImmediatePropagation();
//         $('.loader').addClass('loading');
//         var me = $(this),
//             loadHref = me.attr('href'),
//             loadID = me.attr('id'),
//             loadWrapper = $(this).closest('.load-more-wrapper'),
//             itemContent, nextLoad;

//         loadWrapper.addClass('loading');

//         var ajaxTime = new Date().getTime();

//         $.ajax({
//             url: loadHref,
//             context: document.body,
//             success: function(result) {
//                 var totalTime = new Date().getTime() - ajaxTime,
//                     itemContent = $(result),
//                     loadHtml = itemContent.find('.load-more-content').html().trim(),
//                     nextTrigger = itemContent.find('.load-more-trigger');

//                 me.attr('href', nextTrigger.attr('href'));
//                 console.log(loadHtml.length);
//                 if ( append === true ) {
//                     loadWrapper.find('.load-more-content').append(itemContent.find('.load-more-content').html());
//                 } else {
//                     loadWrapper.html(itemContent);
//                 }

//                 setTimeout(function() {
//                     $('.loader').removeClass('loading');
//                     loadWrapper.removeClass('loading');
//                     Cement.infiniteScroll();
//                     Cement.equalHeights();
//                     Cement.equalHeightsRowless();
//                 }, 500);
//                 if ( loadHtml.length <= 0 ) {
//                     me.hide();
//                 } else {
//                     me.show();
//                 }

//                 if (nextTrigger.attr('href') === undefined) {
//                     me.hide();
//                 }
//             },
//             error: function(response) {
//                 alert('Looks like there was an issue with your connection. Maybe try again?');
//             }
//         }).done(function() {});
//     });
// };
