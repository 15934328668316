/**
 * General Scripts
 *
 * @author Simple Digital
 */

SDG.generalJavaScripture = function() {
    var $ = jQuery;
    var hostname = new RegExp(location.host);

    // Add 'target="_blank"' attribute to all external links
    // $('a[href]').each(function() {
    //     var me = $(this),
    //         url = me.attr('href');
    //     if (hostname.test(url)) {
    //         me.addClass('internal-link');
    //     } else if (url.slice(0, 1) == '/') {
    //         me.addClass('internal-link');
    //     } else if (url.slice(0, 1) == '#') {
    //         // if ( !me.hasClass('filter-item-target') && !me.hasClass('actual-trigger') ) {
    //         //     me.addClass('anchor-link');
    //         // }
    //     } else if (url.indexOf('javascript') !== 0) {
    //         me.addClass('external-link').attr('target', '_blank');
    //     }
    //     if (me.find('img').length > 0) {
    //         me.addClass('image-link');
    //     }
    // });

    // Remove empty paragraphs
    var elemsToEmpty = ['p'];
    elemsToEmpty.forEach(function(elem) {
        $(elem).each(function() {
            // if ( $(this).html().length )
            if ($(this).html().replace(/\s|&nbsp;/g, '').length === 0) {
                $(this).remove();
            }
        });
    });

    $(document).on('click', 'a', function(event) {
        if( $(this).is('[disabled]') ) {
            if ($.GLOBALS.debug) {
                console.log('CLICK ON DISABLED');
            }
            event.preventDefault();
            // event.stopImmediatePropagation();
        }
    });
};


SDG.webAppHacks = function() {
    var $ = jQuery;
    /* Hacks go here */
    // if ( $('.hero').text().trim().length == 0 ) {
    //     $('.hero').addClass('no-underlay');
    // }
};

SDG.fontIconsHacks = function() {
    var $ = jQuery;
    // SDG.equalizeHeightsWidths('.icon-square,.fa.square,.social-icon');
    // $('.flex-module.multi-column.alt-layout article > h2, body.single-case_study .flex-module.multi-column article > h2').fitText(1.2, { minFontSize: '52.5px', maxFontSize: '90px' });
};
