/**
 * Site Search
 */

SDG.searchOverlay = function() {

    $('.search-trigger').on('click', function() {
        $(this).closest('header').toggleClass('overlay-active');
        $('.search-overlay').fadeToggle();
        $('.search-form input').focus();
    });

    $(window).on('keyup', function(e) {
        if (e.keyCode === 27) {
            $('.search-overlay').fadeOut();
        }
    });
    $('.search-form [type="submit"]').hide();
};

SDG.searchForm = function() {
    $('.search-form').on('submit', function(event) {
        var inputVal = $(this).children('input').val();
    });
    $('.search-form input').on('input', function(event) {
        event.preventDefault();
        var inputVal = $(this).val();
        // $('.search-results').empty();
        // if (inputVal === '') {
        //     $('.search-results').empty();
        // }
    });
};
