/**
 * Animation Scripts
 *
 * @author Simple Digital
 */

SDG.animations = function() {
    /* Scroll function courtesy of Scott Dowding;
    http://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling */

    /* Check if element is scrolled into view */
    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    /* If element is scrolled into view, fade it in */
    $(window).on('scroll', function() {
        $('.animated.inactive').each(function() {
            var me = $(this);
            var animateIn = me.data('animation') || 'fadeInUp fast',
                animateOut = me.data('animation-out') || false;
            if (me.visible(true)) {
                me.addClass(animateIn + ' active').removeClass('inactive').removeClass(animateOut);
                if (me.data('remove-animated-class')) {
                    var animationLength = ((parseFloat(getComputedStyle(me[0]).animationDuration)) || 0) * 1000;
                    if (animationLength >= 0) {
                        setTimeout(function() {
                            me.removeClass('animated');
                        }, (animationLength + 200));
                    }
                }
            }
        });
    });
};
