/**
 * Back To Top
 *
 * @author Simple Digital
 */

SDG.backToTop = function() {
    var $ = jQuery;

    if ( $('#back-to-top-link').length === 0 ) {
        $('body footer').append(
            '<a href="javascript:" id="back-to-top-link" class="back-to-top" title="Return to the top of the page.">' +
                '<span class="show-for-sr">Back to Top</span>' +
            '</a>'
        );
    }

    $('.back-to-top').on('click', function(event) {
        event.preventDefault();
        SDG.scrollToElement();
    });


    $(window).scroll(function() {
        if ($(this).scrollTop() >= 200) {
            $('.back-to-top').addClass('active');
            window.setTimeout(function() {
                $('.back-to-top').addClass('idle');
            }, 2000);
        } else {
            $('.back-to-top').removeClass('active');
            window.setTimeout(function() {
                $('.back-to-top').addClass('idle');
            }, 2000);
        }
    });

};
