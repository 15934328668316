/**
 * Forms
 *
 * @author Simple Digital
 */

SDG.formScripture = function() {
//     var $ = jQuery;
//     $('input:text').addClass('form-control');
//     var phoneFields = $('input[type="tel"],input[name="phone"],input.phone');
//     // phoneFields.mask("(999) 999-9999",{autoclear: false});
//     phoneFields.on('focus', function() {
//         this.setSelectionRange(0, this.value.length);
//     });
//     // phoneFields.on("blur", function() {
//     //     var last = $(this).val().substr( $(this).val().indexOf("-") + 1 );

//     //     if( last.length == 3 ) {
//     //         var move = $(this).val().substr( $(this).val().indexOf("-") - 1, 1 );
//     //         var lastfour = move + last;
//     //         var first = $(this).val().substr( 0, 9 );

//     //         $(this).val( first + '-' + lastfour );
//     //     }
//     // });
//     /* Share values with other form fields using `data-share-with=".selector"` */
//     $('[data-value-share]').on('input', function() {
//         var _this = $(this),
//             shareWithFields = $(_this.attr('data-value-share')),
//             shareVals = _this.val().split(' ');
//         $.each(shareWithFields, function(index, value) {
//             if (shareVals[index]) {
//                 $(this).val(shareVals[index]);
//                 $(this).filter(':not(:input)').text(shareVals[index]);
//             } else {
//                 $(this).val('');
//                 $(this).filter(':not(:input)').text(shareVals[index]);
//             }
//         });
//     });

//     /* Autocompletion */
//     $('input.autocomplete').each(function() {
//         var me = $(this);
//         if ($(this).data('completions') !== undefined && $(this).data('completions').indexOf(
//                 ',') > -1) {
//             var options = {
//                 data: me.data('completions').split(','),
//                 list: {
//                     showAnimation: {
//                         type: "fade", //normal|slide|fade
//                         time: 250,
//                         callback: function() {}
//                     },
//                     hideAnimation: {
//                         type: "fade", //normal|slide|fade
//                         time: 250,
//                         callback: function() {}
//                     },
//                     hideOnEmptyPhrase: false,
//                 },
//                 placeholder: me.attr('placeholder'),
//                 minLength: 0
//             };
//             me.easyAutocomplete(options);
//         }
//     });

//     $(document).on('focus', 'input.autocomplete', function() {
//         var me = $(this),
//             autoList = $(this).next('.easy-autocomplete-container').children('ul');
//         if (autoList.length) {
//             if (autoList.find('li').length > 0) {
//                 autoList.show();
//             }
//         }
//     });

//     /* Define your currency field(s) */
//     var currency_input = $('.currency input');
//     if (currency_input.length > 0) {
//         /* Format the currency field when a user clicks or tabs outside of the input */
//         currency_input.blur(function() {
//             $(this).formatCurrency();
//         });
//     }
// };

// /**
//  * Clear Form Input Values
//  * @param Boolean triggerChange - trigger 'change' event after clearing inputs
//  */
// $.fn.clearValues = function(triggerChange) {
//     triggerChange = (typeof triggerChange === 'undefined') ? true : triggerChange;
//     /* Get all inputs that aren't buttons */
//     var inputs = ($(this).filter(':input').length ? $(this).filter(':input') : $(this).find(
//         ':input')).filter(':not(:button,:submit)');

//     inputs.each(function() {
//         /* Checkboxes/Radios */
//         if ($(this).is(':checkbox,:radio')) {
//             $(this).prop('checked', false);
//         } /* else if(this.type == 'file') { var control = $(this); control.replaceWith( control = control.clone( true ) ); } */
//         else {
//             $(this).val('');
//         }
//         if (triggerChange) {
//             $(this).trigger('change');
//         }
//     });
};

/**
 * Disable items
 *
 * @param str toDisable css/jQuery selector
 * @param bool hide
 * @return void
 */
$.fn.disableItems = function(toDisable, hide) {
    hide = (typeof hide !== 'undefined') ? hide : true;
    if ($(this).attr('id') === undefined) {
        $(this).attr('id', $(this).tagName() + '-' + newUniqueID());
    }
    return (
        $(this).find(toDisable)
        .attr('disabled', true)
        .addClass('disabled disabled-item')
        .attr('data-disabled-control', $(this).attr('id'))
        .toggle(!hide)
    );
};

/**
 * Enable item
 *
 * @param bool show
 * @return obj
 */
$.fn.enableItem = function(show) {
    show = (typeof show !== 'undefined') ? show : true;
    return (
        $(this).attr('disabled', false)
        .removeClass('disabled disabled-item')
        .attr('data-disabled-control', null)
        .toggle(show)
    );
};

/**
 * Enable items
 *
 * @param str toDisable css/jQuery selector
 * @param bool show
 * @return obj
 */
$.fn.enableItems = function(toDisable, show) {
    show = (typeof show !== 'undefined') ? show : true;
    toDisable = (typeof toDisable !== 'undefined') ? toDisable : '[data-disabled-control]';
    if ($(this).find(toDisable).length) {
        return (
            $(this).find(toDisable)
            .attr('disabled', false)
            .removeClass('disabled disabled-item')
            .attr('data-disabled-control', null)
            .toggle(show)
        );
    }
};

/**
 * Find form input fields
 *
 * @params target - name attribute value or css/jQuery selector
 * @return obj
 */
$.fn.fields = function(target) {
    var inputs = $(this).find(':input:not(:button)');
    if (typeof target !== 'string') {
        return inputs;
    } else {
        return (
            inputs.filter(target)[0] !== undefined ?
            inputs.filter(target) :
            inputs.filter('[name="' + target + '"]')
        );
    }
};

/**
 * Find form input field values
 *
 * @params target - name attribute value or css/jQuery selector
 * @return obj
 */
$.fn.values = function(target) {
    var inputs = $(this).find(':input:not(:button)'),
        vals = [];
    if (typeof target !== 'string') {
        inputs.each(function() {
            if ($(this).val() !== '') {
                vals.push($(this).val());
            }
        });
    } else {
        inputs = (
            inputs.filter(target)[0] !== undefined ?
            inputs.filter(target) :
            inputs.filter('[name="' + target + '"]')
        );
        inputs.each(function() {
            if ($(this).val() !== '') {
                vals.push($(this).val());
            }
        });
    }
    return vals;
};

/**
 * Validate Zip Code
 *
 * @return bool
 */
$.fn.isValidZip = function() {
    return (/(^\d{5}$)|(^\d{5}-\d{4}$)/).test($(this).val());
};

/**
 * Populate form with data passed as JSON object
 *
 * @param JSON data
 * @param  fn callbacks
 * @return void
 */
$.fn.populateForm = function(data, callbacks) {
    if ($(this).is("form")) {
        var formInstance = this;

        var options = {
            data: data || null,
            callbacks: callbacks
        };

        if (options.data != null) {
            $.each(options.data, function(k, v) {
                if (options.callbacks != null && options.callbacks.hasOwnProperty(k)) {
                    options.callbacks[k](v);
                } else {
                    var inp = $(formInstance).find(':input[name="' + k + '"]'),
                        multiInp = false;
                    if (inp.length == 0) {
                        inp = $(formInstance).find(':input[name^="' + k + '"]');
                        multiInp = true;
                    }
                    inpType = inp.first().attr('type');
                    if (inp.length > 0) {
                        switch (inpType) {
                            case "radio":
                            case "checkbox":
                                var inpNames = [];
                                inp.prop('checked', false);
                                if (multiInp == true) {
                                    inp.each(function() {
                                        var n = $(this).attr('name');
                                        // console.log(n);
                                        if (n.indexOf('[') > -1 && n.indexOf(
                                                ']') > -1) {
                                            inpNames.push(n);
                                            var innerIndex = n.split('[')[1].replace(
                                                ']', '');
                                            //console.log(innerIndex);
                                            var allVals = options.data[k][
                                                innerIndex
                                            ];
                                            if ($(this).val() === allVals || $.inArray(
                                                    $(this).val(), allVals) > -
                                                1) {
                                                $(this).prop('checked', true);
                                            }
                                        } else {

                                        }
                                    });
                                } else {

                                }
                                inp.filter('[value="' + v + '"]').prop('checked', true);
                                inp.trigger('change');
                                break;
                            default:
                                inp.val(v);
                                inp.trigger('change');
                                break;
                        }
                    }
                }
            });
        }
        $(formInstance).find(':input').each(function() {
            $(this).trigger('change');
        });
    }
};
