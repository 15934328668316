/**
 * Accordions
 *
 * @author Simple Digital
 */

/* Collapsible Accordions */
SDG.collapsibleAccordions = function() {
    var $ = jQuery;
    if ($('.accordions').length) {
        $('.accordions').each(function() {

            var set = $(this),
                toggle = set.hasClass('no-toggle') === false;

            set.on('click', '.trigger', function(event) {
                var accordionParent = $(this).parents('.accordion'),
                    sendTrigger = accordionParent.hasClass('active') ? 'collapsed' : 'expanded';

                /* Add active states */
                accordionParent.toggleClass('active').trigger(sendTrigger);

                /* Hide the other panels */
                if (toggle === true) {
                    accordionParent.siblings().removeClass('active').trigger('collapsed');
                }
            });
        });
    }
    $('[data-expand-target]').on('click', function() {
        var expandTarget = '.collapsible#' + $(this).data('expand-target');
        // $(this).parents('.data-block').trigger('click');
        // console.log($(this).parents('.data-block'));
        if ($(expandTarget).hasClass('collapsed')) {
            SDG.scrollToElement(expandTarget);
        }
        $(expandTarget).toggleClass('collapsed');
    });
    $('a[href="#workflows"]').on('click', function(event) {
        event.preventDefault();
        var expandTarget = '#workflows';
        // $(this).parents('.data-block').trigger('click');
        // if ( $(this).data('template') !== undefined ) {
        //     $(expandTarget).find('[name="template_name"]').val()
        // }
        if ($(expandTarget).length) {
            SDG.scrollToElement(expandTarget);
            $(expandTarget).removeClass('collapsed');

        }
    });
};
