/**
 * Common JS
 *
 * @author Simple Digital
 */

/**
 * Create new unique ID
 */
function newUniqueID() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

/**
 * Clean a slug up
 */
 function cleanSlug(text) {
     return (
         text.toString().toLowerCase()
         .replace(/\s+/g, '-') /* Replace spaces with - */
         .replace(/[^\w\-]+/g, '') /* Remove all non-word chars */
         .replace(/\-\-+/g, '-') /* Replace multiple '--' with '-' */
         .replace(/^-+/, '').replace(/-+$/, '') /* Trim text */
         .replace(/_/g, '-') /* Replace underscores with dashes */
     );
 }

 function slugifyURL(url) {
     if ( url.indexOf('/') > -1 ) {
         url = url.substring(url.lastIndexOf('/') + 1);
     }
     return cleanSlug(url);
 }

/**
 * Clean empty items from object
 */
SDG.cleanObject = function(obj) {
    var $ = jQuery;
    var newObj = {};
    Object.keys(obj).forEach(function(prop) {
        if (obj[prop]) {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
};

/**
 * Push history state to browser and update page title
 */
SDG.pushHistoryState = function(permalink) {
    var $ = jQuery;
    if (history.pushState) {
        window.history.pushState(null, permalink.replace('/', ''), permalink);
    }
};

SDG.pushHashState = function(permalink) {
    var $ = jQuery;
    if (history.pushState) {
        window.history.pushState(null, permalink.replace('/', ''), permalink);
    }
};

SDG.isMobile = function(size) {
    var $ = jQuery;
    size = (typeof size === 'undefined') ? $.GLOBALS.mediumBreak : size;
    return ($(window).width() <= size);
};

SDG.getHashPath = function(url) {
    var $ = jQuery;
    url = (typeof url == 'undefined') ? document.location.hash : url;
    return (url.indexOf('#') > -1) ? url.split('#')[1] : '';
};

SDG.getHashSlug = function(url) {
    var $ = jQuery;
    url = (typeof url == 'undefined') ? document.location.hash : url;
    return SDG.getHashPath(url).replaceAll('/', '');
};

SDG.getParameterByName = function(name, url) {
    var $ = jQuery;
    if (typeof url === 'undefined') {
        url = window.location.href;
    }
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Scroll To Element
 *
 * @param string selector [optional | default: 'body'] - the selector of the element to scroll to
 * @param int time [optional | default 1000] - time in miliseconds for slide transition
 * @param string overrideselector [optional | default: 'html, body'] - the selector of the animate element
 * @return void
 */
SDG.scrollToElement = function(selector, offset, time, overrideselector) {
    var $ = jQuery;
    $('html,body').addClass('scrollable');
    selector = (typeof selector === 'undefined') ? 'body' : selector;
    offset = (typeof offset === 'undefined') ? 0 : offset;
    time = (typeof time === 'undefined') ? 1000 : time;
    overrideselector = (typeof overrideselector === 'undefined') ? 'html, body' : overrideselector;
    if ($(selector).length) {
        $(overrideselector).animate({
            scrollTop: $(selector).offset().top + (offset)
        }, time);
    }
    $('html,body').removeClass('scrollable');
};

SDG.equalHeightsSmallest = function() {
    var $ = jQuery;
    var equalHeights = $('.equal-heights-smallest');
    equalHeights.removeClass('equalized');
    windowWidth = $(window).width();
    var sections;
    if (equalHeights.length > 0) {
        equalHeights.each(function() {
            if (windowWidth > $.GLOBALS.mediumBreak || $(this).hasClass(
                    'equal-heights-all')) {
                sections = $(this).find('.equal-smallest');
                h = $(this).innerHeight();
                sections.height('auto');
                sections.each(function() {
                    // console.log($(this).height());
                    newHeight = $(this).innerHeight();
                    if (newHeight < h && newHeight > 0) {
                        h = newHeight;
                    }
                });
                sections.css('height', h + 'px');
                $(this).addClass('equalized');
            } else {
                $(this).find('.equal-smallest').css('height', 'auto');
            }
        });
    }
};

/**
 * Equal Heights for Columns
 *
 * Add class 'equal-heights' to outer element & 'equal' to elements to be equalized
 */
SDG.equalHeights = function() {
    var $ = jQuery;
    if (typeof(SDG.equalHeightsInner) === 'function') {
        var equalHeightsInner = new SDG.equalHeightsInner();
    }
    var equalHeights = $('.equal-heights');
    equalHeights.removeClass('equalized');
    windowWidth = $(window).width();
    if (equalHeights.length > 0) {
        equalHeights.each(function() {
            if (windowWidth > $.GLOBALS.mediumBreak || $(this).hasClass(
                    'equal-heights-all')) {
                var sections = $(this).find('.equal'),
                    largestHeight = 0;
                sections.height('auto');
                sections.each(function() {
                    var h = $(this).innerHeight();
                    if (largestHeight < h) {
                        largestHeight = h;
                    }
                });
                sections.css('height', largestHeight + 'px');
                $(this).addClass('equalized');
            } else {
                $(this).find('.equal').css('height', 'auto');
            }
        });
    }
};

SDG.equalHeightsInner = function(innerClass) {
    var $ = jQuery;
    if (typeof innerClass === 'undefined') {
        innerClass = 'equal-inner';
    }
    var equalHeights = $('.equal-heights');
    equalHeights.removeClass('equalized-inner');
    windowWidth = $(window).width();
    if (equalHeights.length > 0) {
        equalHeights.each(function() {
            if (windowWidth > $.GLOBALS.mediumBreak || $(this).hasClass(
                    'equal-heights-all')) {
                var sections = $(this).find('.' + innerClass),
                    largestHeight = 0;
                sections.height('auto');
                sections.each(function() {
                    var h = $(this).innerHeight();
                    if (largestHeight < h) {
                        largestHeight = h;
                    }
                });
                sections.css('height', largestHeight + 'px');
                $(this).addClass('equalized-inner');
            } else {
                $(this).find('.' + innerClass).css('height', 'auto');
            }
        });
    }
};

/**
 * Equal Heights for Columns on Mobile
 *
 * Add class 'equal-heights-mobile' to outer element & 'equal' to elements to be equalized
 */
SDG.equalHeightsMobile = function() {
    var $ = jQuery;
    var equalHeights = $('.equal-heights-mobile');
    windowWidth = $(window).width();
    if (windowWidth <= $.GLOBALS.mediumBreak) {
        // var desktopToo = $(this).find('.equal')
        if (equalHeights.length > 0) {
            equalHeights.each(function() {
                var sections = $(this).find('.equal').filter(function() {
                        return $(this).parents('.equal-heights').length === 0;
                    }),
                    largest_height = 0;
                $(this).removeClass('equalized');
                sections.height('auto');
                sections.each(function() {
                    var h = $(this).innerHeight();
                    if (largest_height < h) {
                        largest_height = h;
                    }
                });
                sections.css('height', largest_height + 'px');
                $(this).addClass('equalized');
            });
        }
    }
};

/**
 * Equal Widths for Elements
 *
 * Add class 'equal-widths' to outer element & 'equal' to elements to be equalized
 */
SDG.equalWidths = function(useSmallest) {
    var $ = jQuery;
    var compareSizes = function(a, b) {
        return (SDG.isSet('useSmallest') ? useSmallest : false) ? (a > b) : (a < b);
    };
    var equalWidths = $('.equal-widths');
    equalWidths.removeClass('equalized');
    windowWidth = $(window).width();
    if (windowWidth > $.GLOBALS.mediumBreak) {
        if (equalWidths.length > 0) {
            equalWidths.each(function() {
                var sections = $(this).find('.equal'),
                    useWidth = 0;

                sections.width('auto');

                if (sections.length > 1) {
                    sections.each(function() {
                        var h = $(this).innerWidth();
                        if (compareSizes(useWidth, h)) {
                            useWidth = h;
                        }
                    });
                    sections.css('width', useWidth + 'px');
                }

                $(this).addClass('equalized');
            });
        }
    } else {
        equalWidths.find('.equal').css('width', 'auto');
    }
};

/**
 * Equal Column Heights & Widths
 *
 * Add class 'equal-height-width' to elements to be equalized
 */
SDG.equalizeHeightsWidths = function(selector) {
    var $ = jQuery;
    selector = (typeof selector === 'undefined') ? '.equal-height-width' : selector;
    var equalWidths = $(selector);
    if (equalWidths.length > 0) {
        equalWidths.each(function() {
            $(this).height('auto').width('auto');
            var w = $(this).outerWidth(),
                h = $(this).outerHeight(),
                size = (w > h) ? w : h;
            $(this).css({
                height: size,
                width: size
            });
        });
    }
};

/**
 * Return the first relative parent of an element
 *
 * @param child element
 * @return Object
 * @uses jQuery
 */
SDG.closestRelativeParent = function(elem) {
    var $ = jQuery;
    var rel = false,
        par = elem.parent();
    while (rel === false) {
        rel = (par.css('position') === 'relative' || par.is('body'));
        if (rel !== true) {
            par = par.parent();
        }
    }
    return par;
};

/**
 * Get page title of provided URL
 *
 * @param url
 * @return String
 */
SDG.pageTitle = function(url, update) {
    var $ = jQuery;
    var result = "";
    update = update || false;
    $.ajax({
        url: url,
        async: false,
        success: function(data) {
            result = data;
        }
    });
    if (update) {
        document.title = result.match(/<(title)>[\s\S]*?<\/\1>/gi)[0].replace('<title>', '').replace(
            '</title>', '');
    }
    return result.match(/<(title)>[\s\S]*?<\/\1>/gi)[0].replace('<title>', '').replace(
        '</title>', '');
};

/**
 * Check if variable is defined
 *
 * @usage isSet('varNameAsString')
 *
 * @param varAsStr string - variable name as string
 * @param returnIfSet bool [default => false] - return the variable value if set
 * @return Bool
 */
SDG.isSet = function(varAsStr, returnIfSet) {
    returnIfSet = typeof returnIfSet === 'undefined' ? false : returnIfSet;

    var $ = jQuery;

    var prevItem = window,
        varSplit = varAsStr.split('.'),
        varIsSet = false;

    if (typeof(window[varSplit[0]]) !== 'undefined') {
        obj = window[varSplit[0]];
    }

    $.each(varSplit, function(i, item) {
        if (typeof(item) === 'undefined') {
            return false;
        } else {
            if (typeof prevItem[item] === 'undefined') {
                varIsSet = false;
            } else {
                prevItem = prevItem[item];
                if (i === (varSplit.length - 1)) {
                    if (typeof(item) !== 'undefined') {
                        varIsSet = (typeof(prevItem) !== 'undefined');
                    } else {
                        varIsSet = false;
                    }
                }
            }
        }
    });
    if ( varIsSet && returnIfSet ) {
        return SDG.getIfDefined(varAsStr);
    }
    return varIsSet;
};

// SDG.getIfSet = function(varAsStr) {
//     if ( SDG.isSet(varAsString) ) {
//         return
//     }
// }

/**
 * Check if variable is defined
 *
 * @param variable name as string
 * @return Bool
 * @usage isSet('varNameAsString')
 */
SDG.ifSetElse = function(varAsStr, elseReturn) {
    var $ = jQuery;
    elseReturn = (typeof elseReturn === 'undefined') ? null : elseReturn;
    if (isSet(varAsStr)) {
        return window[varAsStr];
    } else {
        if ( elseReturn !== null ) {
            return elseReturn;
        }
    }
};

/**
 * Count the properties of a JavaScript Object
 *
 * @param Object
 * @return int
 */
SDG.countProperties = function(obj) {
    var $ = jQuery;
    var count = 0;
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) { ++count; }
    }
    return count;
};

/**
 * Find elements w/ duplicate attributes in DOM
 *
 * @param string
 */
SDG.findDuplicateAttributes = function(attr) {
    var $ = jQuery;
    $('[' + attr + ']').each(function() {
        var _this = $(this),
            allAttributes = $('[' + attr + '="' + _this.attr(attr) + '"]');
        if (allAttributes.length > 1 && allAttributes[0] == this) {
            console.log(_this);
        }
    });
};

/**
 * Append external JS file to body
 */
SDG.addJavascript = function(jsname) {
    if (jsname.length > 0) {
        scriptElem = document.createElement('script');
        scriptElem.setAttribute('type', 'text/javascript');
        scriptElem.setAttribute('src', jsname);
        document.body.appendChild(scriptElem);
    }
};

/**
 * Append external CSS file to body
 */
SDG.addCSS = function(cssname) {
    if (cssname.length > 0) {
        if ( cssname.indexOf('{') == -1 && cssname.indexOf('}') == -1 ) {
            elem = document.createElement('link');
            elem.setAttribute('rel', 'stylesheet');
            elem.setAttribute('href', cssname);
        } else {
            elem = document.createElement('style');
            elem.setAttribute('type', 'text/css');
            elem.innerText = cssname;
        }
        document.body.appendChild(elem);
    }
};

/**
 * String Helpers
 * @param string str
 * @return str
 */
SDG.toCamelCase = function(str) {
    var $ = jQuery;
    return str.toString().replace(/(?:^\w|\-[A-Z]|\b\w)/g, function(letter, index) {
        return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '').replace('-', '');
};

SDG.areCookiesEnabled = function() {
    var $ = jQuery;
    return (navigator.cookieEnabled);
};

/**
 * Site Alert
 *
 * @param string message - the alert text/html content
 * @param string style - available options include: ['success', 'info', 'warning', 'error'] (default is 'info')
 * @param int timeout - number of milliseconds to show the alert (default is 7000)
 * @return void
 */
SDG.siteAlert = function(message, style, timeout) {
    var $ = jQuery;
    style = typeof style === 'undefined' ? 'info' : style;
    timeout = typeof timeout === 'undefined' ? 7000 : timeout;
    if ($('.site-alert').length === 0) {
        $('body').append('<div class="site-alert"></div>');
    }
    refillAlert(message, style);
    function refillAlert(message, style) {
        $('.site-alert').empty().append('<div class="content ' + style + '">' + message +
            '</div>');
        $('.site-alert').addClass('active');
        clearTimeout($.GLOBALS.alertTimeout);
        $.GLOBALS.alertTimeout = setTimeout(
            function() {
                $('.site-alert').fadeOut('slow', function() {
                    $(this).empty().show().removeClass('active');
                });
            }, timeout
        );
    }
};

/**
 * Validate an Email Address
 */
SDG.validateEmail = function(email) {
    var $ = jQuery;
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

/**
 * Validate USD Currency
 *
 * @param  str val
 * @param  bool strict - if false, will allow missing digit (default is true)
 * @return bool
 */
validateUSD = function(val, strict) {
    var regex = (typeof strict === 'undefined' || strict == true) ? /^\d+(?:\.\d{2})$/ : /^\d+(?:\.\d{0,2})$/;
    return (regex.test(val));
};

/**
 * Convert to Boolean
 */
toBool = function(val) {
    return (false == (['false', '0', '', 'undefined', null, NaN].indexOf(String(val).toLowerCase().trim()) + 1));
};

SDG.defined = function(varAsStr) {
    'use strict';
    /* jshint evil: true */
    var isDefined = eval("typeof " + varAsStr + " !== 'undefined'");
    if (typeof isDefined === 'boolean') {
        return isDefined;
    }
};

SDG.getIfDefined = function(varAsStr, fallback) {
    'use strict';
    /* jshint evil: true */
    var fallbackVal = typeof fallback === 'undefined' ? null : fallback;
    if ( SDG.defined(varAsStr) ) {
        return eval(varAsStr);
    } else {
        if ( fallbackVal !== null ) {
            return fallbackVal;
        }
    }
};


/**
 * Aliases
 */
isSet = SDG.isSet;
getIfDefined = SDG.getIfDefined;
