/**
 * Scrollify
 *
 * @author Simple Digital
 */

SDG.scrollifySnap = function() {
    var $ = jQuery;
    var scrollifySelectors = '.scrollify';
    //$('.hero:first').addClass('scrollify');
    $.scrollify({
        section : scrollifySelectors,
        sectionName : "section-name",
        interstitialSection:".hero.preview,.flex-module,footer.site-footer",
        easing: "easeOutExpo",
        scrollSpeed: 1000,
        offset : 0,
        scrollbars: true,
        //standardScrollElements: "section",
        setHeights: false,
        overflowScroll: true,
        updateHash: false,
        touchScroll: true,
        before:function() {},
        after: function(a) {
            // console.log(a);
            // console.log(this);
        },
        afterResize:function() {},
        afterRender:function() {}
      });

};
