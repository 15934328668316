/**
 * Loading Modules
 */

SDG.loaders = {

    toggleLoading: function(show, container /*='.loading-container'*/, appendTo /*='body'*/ ) {
        container = (typeof container !== 'undefined') ? container : '.loading-container';
        appendTo = (typeof appendTo !== 'undefined') ? appendTo : 'body';
        if ( $(container).length === 0 ) {
            if ( apppendTo !== false && $(appendTo).length) {
                $(appendTo).append($.GLOBALS.contentLoader.addClass('loading'));
            }
        }
        if ( show ) {
            $(container).addClass('loading');
        } else {
            $(container).removeClass('loading');
        }
    },

    toggleAfter: function(show, appendTo) {
        if ( typeof appendTo !== 'undefined' && appendTo.length !== 0 ) {
            if (show === true) {
                $('.tmp-loader').remove();
                $(appendTo).after($.GLOBALS.relativeLoader);
                $(appendTo).next('.loading-container').addClass('loading');
            } else {
                $('.tmp-loader').removeClass('loading').fadeOut(800, function() {
                    $(this).remove();
                });
            }
        }
    },

    toggle: function(show) {
        if ( $('.loading-container').length === 0 ) {
            $('body').append($.GLOBALS.contentLoader.addClass('loading'));
        }
        if ( show ) {
            $('.loading-container').addClass('loading');
        } else {
            $('.loading-container').removeClass('loading');
        }
    },

    show: function() {
        SDG.loaders.toggle(true);
    },

    hide: function() {
        $('.loading-container').
        SDG.loaders.toggle(false);
    }
};
