/**
 * Bookmarks
 *
 * @author Carely
 */

SDG.browserBookmarker = function() {
    var $ = jQuery;
    /* Several tests are necessary in order for this "simple" action to work in most of the browsers */
    $(document).on('click', '.bookmark-link', function(event) {
        // If you have something in the `href` of your trigger
        event.preventDefault();

        if (window.sidebar && window.sidebar.addPanel) { // Firefox <23
            window.sidebar.addPanel(document.title,window.location.href,'');
        } else if(window.external && ('AddFavorite' in window.external)) { // Internet Explorer
            window.external.AddFavorite(location.href,document.title); 
        } else if(window.opera && window.print || window.sidebar && ! (window.sidebar instanceof Node)) { // Opera <15 and Firefox >23
            /** For Firefox <23 and Opera <15, no need for JS to add to bookmarks */
            $(this).attr('rel', 'sidebar').attr('title', document.title).attr('href', window.location.href);
            return true;
        } else { // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D    
            alert('Save this page to your bookmarks by pressing "' + (navigator.userAgent.toLowerCase().indexOf('mac') != - 1 ? 'Command/Cmd' : 'CTRL') + ' + D".');
        }
    });

};
