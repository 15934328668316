/**
 * History Scripts
 *
 * @author Carely
 */

SDG.historyEvents = function() {
    var $ = jQuery;
    /* log */
    /*var log = function(msg) {
        console.log(msg);
    };*/

    // Event handlers
    /*$.address

        .init(function(event) {
            log('init: "' + event.value + '"');
            $('a').address(function() {
                return $(this).attr('href').replace(location.pathname, '');
            });
        })

        .change(function(event) {
            log('change: "' + event.value + '"');
            var text = (event.value == '') ? 'Home' :
                event.pathNames[0].substr(0, 1).toUpperCase() +
                event.pathNames[0].substr(1);
            $('a').each(function() {
                $(this).toggleClass('selected', $(this).text() == text);
            });
        })

        .internalChange(function(event) {
            log('internalChange: "' + event.value + '"');
        })

        .bind('externalChange', {
            msg: 'The value of the event is "{value}".'
        }, function(event) {
                console.log(event);
                log('externalChange: ' + event.data.msg.replace(/\{value\}/, event.value));
        }
    ); */
};
