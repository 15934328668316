/**
 * Cards
 *
 * @author Simple Digital
 */

SDG.cardTricks = function() {
    var $ = jQuery;
    if ($('.card a').length) {
        $('.card:not(.card-link)').each(function() {
            if ($(this).find('a').length) {
                $(this).addClass('card-link');
            }
        });
        $('.card-link').on('click', function() {
            var firstLink = $(this).find('a:last');
            if (firstLink.length == 1) {
                firstLink.click();
            }
        });
    }
};
